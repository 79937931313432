body {
    min-width: inherit;
}

.body .mod_wrap .mod_inner .mod_step .mod_result .text_area {
    width: 900px;
}

.btn_blue {
    height: 33px;
    min-width: 24px;
    padding: 0 20px;
    border: 1px solid #0064b6;
    border-radius: 2px;
    background: #2277da;
    color: #fff;
    font-size: 14px;
    line-height: 33px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    outline: 0;
    margin: 0 10px;
}
.btn_common {
    border: 1px solid #2277da;
    background: #fff;
    color: #2277da;
    height: 33px;
    min-width: 24px;
    padding: 0 20px;
    border-radius: 2px;
    font-size: 14px;
    line-height: 33px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    outline: 0;
    margin: 0 10px;
    vertical-align: bottom;
}
.btn_disabled {
    border-color: #aacfff;
    background-color: #aacfff;
    color: #fff;
}
.btn_disabled:hover {
    border-color: #aacfff;
    background-color: #aacfff;
    color: #fff;
}

.mod_step .mod_result .des .btn {
    font: 400 14px/33px "微软雅黑";
}

.body .mod_wrap .mod_inner .mod_step .mod_result .des {
    color: #999;
}

.other-tips {
    text-align: center;
    color: #5ca0ef;
}

.mod_result {
    padding: 250px 0 !important
}

@media screen and (max-width: 768px) {
    .body {
        min-width: auto;
        width: auto;
    }

    .body .mod_inner {
        width: auto;
    }

    .body .mod_wrap .mod_inner .mod_step .mod_result .text_area {
        width: auto;
        padding: 0 20px;
    }

    .invite_wrap .tit_h2 {
        padding: 0 50px;
    }

    .other-tips {
        text-align: center;
        line-height: 20px;
        font-size: 15px;
        margin-top: 6px;
        color: #5ca0ef;
    }

    .body .mod_wrap .mod_inner .mod_step .mod_result .des {
        line-height: 19px;
        font-size: 15px;
    }

    .mod_result {
        padding: 20px 0 !important
    }
    .invitation-btn-area .btn_common {
        display: block;
        margin: 0 auto;
    }
    .invitation-btn-area .btn_blue {
        display: block;
        margin: 0 auto;
    }
    .invitation-btn-area .btn_common {
        max-width: 250px;
        margin-bottom: 10px;
    }
    .invitation-btn-area .btn_blue {
        max-width: 250px;
        width: auto;
    }}

.disabled {
    border-color: #d5d5d5;
    background: #e8e8e8;
    color: #a2a2a2;
    cursor: not-allowed;
}

.partner-text-left {
    text-align: left;
}

.btn_cancel {
    color: #000;
    background: #fff;
    border: 1px solid;
}
.rno-modal-inner {
    width: 500px;
}
.rno-modal-inner .invitation-confirm-title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}
.icon-loading {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTggMTZBOCA4IDAgMSAxIDggMHYyYTYgNiAwIDEgMCA2IDZoMmE4IDggMCAwIDEtOCA4eiIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
    -webkit-animation: spin 0.6s linear infinite;
    animation: spin 0.6s linear infinite;
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: text-top;
}
.apply-ensure-checkbox {
    font-size: 14px;
    color:#999;
    display: block;
    margin-top: 20px;
}