body {
    min-width: inherit;
    background: #eee !important;
  }

  .check-box {
    margin: 50px auto;
    padding: 60px 20px;
    width: 90%;
    max-width: 900px;
    text-align: center;
    border: 1px solid #ddd;
    background: #fff;
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    .body {
        min-width: auto;
        width: auto;
    }
    .body .mod_inner {
        width: auto;
    }
    .check-box {
    margin: 0 auto;
  }
  }

  .check-tips {
    color: #999;
    margin-bottom: 20px;
  }

  .result-fail {
    text-align: left;
    display: flex;
    margin: 0 auto;
  }

  .result-error-icon {
    color: rgb(247, 64, 66);
    font-size: 30px;
  }

  .check-msg {
    margin-bottom: 20px;
  }

  .check-title {
    font-size: 20px;
    margin-bottom: 10px;
  }